.item {
  position: relative;
  min-height: 100px;
  border: 1px solid #f1f1f1;
  overflow: hidden;
}

.watermark {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  font-weight: bold;
  color: rgb(0 0 0 / 1%);
  z-index: 0;
  pointer-events: none;
  padding-left: 10px;
  white-space: nowrap;
}
